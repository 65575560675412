import Dashboard from "../../pages/Dashboard/Dashboard";
import List from "../../pages/List/List";

const Layout = () => {
    return (
        <>
            {/* <List /> */}
            {/* <Dashboard /> */}
        </>
    );
}

export default Layout;