import './Footer.scss';

const Footer = () => {
    const linkedIn = 'https://in.linkedin.com/';
    const github = 'https://github.com/';
    const facebook = 'https://www.facebook.com/';
    const instgram = 'https://www.instagram.com/';

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-dark">
            <div className="container-fluid justify-content-end">
                <span className="navbar-brand text-white">Where To Find Me?</span>
                <a className="navbar-brand text-white" href={linkedIn} target="_blank">
                    <i className="bi bi-linkedin"></i>
                </a>
                <a className="navbar-brand text-white" href={github} target="_blank">
                    <i className="bi bi-github"></i>
                </a>
                <a className="navbar-brand text-white" href={facebook} target="_blank">
                    <i className="bi bi-facebook"></i>
                </a>
                <a className="navbar-brand text-white" href={instgram} target="_blank">
                    <i className="bi bi-instagram"></i>
                </a>
            </div>
        </nav>
    );
}

export default Footer;