import Layout from '../Layout/Layout';
import './Menu.scss';

const Menu = () => {
  return (
    <div className="container-fluid">
      <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
          <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
            <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
              <li className="nav-item">
                <a href="#" className="nav-link align-middle px-0">
                  <i className="bi bi-google"></i>
                  <span className="ms-1 d-none d-sm-inline">Sign In Google</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link align-middle px-0">
                  <i className="bi bi-microsoft"></i>
                  <span className="ms-1 d-none d-sm-inline">Sign In Microsoft</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col py-3">
          <Layout />
        </div>
      </div>
    </div>
  );
}

export default Menu;