import { useEffect, useState } from 'react';
import profilepic from '../../assets/profilepic.png';
import './Header.scss';

const Header = () => {
    const [username, setUsername] = useState('');

    /** In order to update user name. */
    useEffect(() => {
        setUsername('Amit Singh Tomar');
    }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand text-white ms-2" href="#">Login</a>
                <div className="navbar-nav mb-2 mb-lg-0 align-items-center">
                    <span className="navbar-brand text-white">Hi, I am {username}</span>
                    {/* In order to show profilepic of user from assets. */}
                    <img src={profilepic} className="rounded-circle profilepic" alt={username} />
                </div>

            </div>
        </nav>
    );
}

export default Header;